import React, { useEffect, useRef, useState } from 'react'; //import React Component
import styles from './Employment.module.css';
import { Bg } from '../../Components/Bg/Bg.js';
import { Footer } from '../../Components/Footer/Footer.js';
import { MenuS } from '../../Components/Menu/MenuS.js';

export function Employment() {
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    //----
    //line
    //----
    //----
    const [employmentsHeight, setEmploymentsHeight] = useState(0)
    const employmentsRef = useRef(null)
    useEffect(() => {
        setEmploymentsHeight(employmentsRef.current.offsetHeight - 40)
    }, [])

    //---
    //卡片
    //---
    //---
    // 翻转卡片
    const frontRef = useRef([])
    const backRef = useRef([])
    const turnside = (e) => {
        let { value } = e.target.dataset
        if (e.target.classList.contains('turn')) {
            e.target.classList.remove("turn");//设置成为未翻转的卡片
            frontRef.current[value].style.transform = "none"
            backRef.current[value].style.transform = "rotateY(180deg)"
        } else {
            e.target.classList.add("turn");//设置成为已翻转的卡片
            frontRef.current[value].style.transform = "rotateY(180deg)"
            backRef.current[value].style.transform = "none"
        }
    }

    // 设置卡片正面高度为背面高度，并获得卡片的高和宽
    const [cardHeight, setCardHeight] = useState([])
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    useEffect(() => {
        backRef.current.forEach((back, index) => {
            frontRef.current[index].style.height = `${back.offsetHeight}px`
        })
        setCardHeight(backRef.current.map((prevHeight) => prevHeight.offsetHeight));
    }, []);

    //菜单三件套
    //click menu后设置部分变量
    const [menu, setMenu] = useState(false)
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const menuButtonRef = useRef([])
    useEffect(() => {
        if (menu) {
            menuButtonRef.current.forEach((menuButton) => {
                menuButton.style.opacity = "1"
                menuButton.style.pointerEvents = "all"
            })
        } else {
            menuButtonRef.current.forEach((menuButton) => {
                menuButton.style.opacity = "0"
                menuButton.style.pointerEvents = "none"
            })
        }
    }, [menu])

    //切换页面
    const mainRef = useRef(null)
    const titleRef = useRef(null)
    const decorateRef = useRef(null)
    const changePage = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        mainRef.current.style.opacity = "0"
        decorateRef.current.style.opacity = "0"
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"

        setTimeout(() => {
            topFunction()
            window.location.href = `/${value}`
        }, 400);
    }

    //animation-timeline
    const [scrollPercentage, setScrollPercentage] = useState(0)
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolled = (scrollTop / (scrollHeight - clientHeight)).toFixed(2);

        setScrollPercentage(scrolled);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // console.log(scrollPercentage)

    // const lineRef = useRef(null);
    const bgFrameRef = useRef(null);
    const bgFrame2Ref = useRef(null);
    const scrollAnimationRef = useRef(null);
    useEffect(() => {
        // lineRef.current.style.height = `${scrollPercentage * (employmentsHeight - 150)}px`
        bgFrameRef.current.style.bottom = `${-50 * (1 - scrollPercentage)}vh`
        bgFrame2Ref.current.style.top = `${-50 * (scrollPercentage)}vh`

        //星球的
        if (scrollPercentage < 0.33333) {
            scrollAnimationRef.current.style.transform = `translate(${(0.95 * screenWidth / 2 - 80) * -3 * scrollPercentage}px, ${(cardHeight[0] / 2 + cardHeight[1] / 2 + 80) * 3 * scrollPercentage}px)`;
        } else if (scrollPercentage < 0.66666) {
            scrollAnimationRef.current.style.transform = `translate(${(0.95 * screenWidth / 2 - 80) * -3 * (1 - scrollPercentage - 0.33)}px, ${(cardHeight[0] / 2 + cardHeight[1] / 2 + 80) + ((cardHeight[1] / 2 + cardHeight[2] / 2 + 80) * (scrollPercentage - 0.33) * 3)}px)`;
        } else if (scrollPercentage <= 1) {
            scrollAnimationRef.current.style.transform = `translate(${(0.95 * screenWidth / 2 - 80) * (scrollPercentage - 0.66) * -3}px, ${(cardHeight[0] / 2 + cardHeight[1] / 2 + 80) + (cardHeight[1] / 2 + cardHeight[2] / 2 + 80) + ((cardHeight[2] / 2 + cardHeight[3] / 2 + 80) * (scrollPercentage - 0.66) * 3)}px)`;
        }
    }, [scrollPercentage])

    //设置随机五彩chunk颜色
    useEffect(() => {
        if (frontRef) {
            let colorset = ["rgba(193, 168, 196, 0.3)", "rgba(196, 174, 168, 0.3)", "rgba(196, 185, 168, 0.3)", "rgba(196, 196, 168, 0.3)", "rgba(169, 196, 168, 0.3)", "rgba(168, 196, 195, 0.3)", "rgba(168, 172, 196, 0.3)"]
            frontRef.current.forEach((front, index) => {
                let color = colorset[Math.floor(Math.random() * (colorset.length - index))];
                colorset = colorset.filter((color_used) => { return color_used !== color })
                front.style.backgroundColor = color
                backRef.current[index].style.backgroundColor = color
            });
        }
    }, [])
    return (
        <div id={styles.Employment}>
            <div className={styles.title} ref={titleRef}>
                <h1 className='color_change2'>
                    Employment
                </h1>
            </div>
            <div className={styles.bgFrame} ref={bgFrameRef}></div>
            <div className={styles.bgFrame2} ref={bgFrame2Ref}></div>
            <main ref={mainRef}>
                <div className={styles.decorate} ref={decorateRef}>
                    <div></div>
                </div>
                <div className={styles.employments} ref={employmentsRef}>
                    {/* <div className={styles.path}>
                        <div className={styles.line} ref={lineRef}></div>
                    </div> */}
                    <div className={styles.scrollAnimation} ref={scrollAnimationRef} style={{ gridColumn: "2", gridRow: "1" }}>
                        <div className={styles.circle} onClick={openMenu}>
                            <div>Menu</div>
                        </div>
                        <div className={styles.menuButton} style={{ "--deg": `${360 / 3 * 1}deg` }} ref={(e) => menuButtonRef.current[0] = e} onClick={(e) => changePage(e)} data-value="Research">
                            Research
                        </div>
                        <div className={styles.menuButton} style={{ "--deg": `${360 / 3 * 2}deg` }} ref={(e) => menuButtonRef.current[1] = e} onClick={(e) => changePage(e)} data-value="Projects">
                            Projects
                        </div>
                        <div className={styles.menuButton} style={{ "--deg": `${360 / 3 * 3}deg` }} ref={(e) => menuButtonRef.current[2] = e} onClick={(e) => changePage(e)} data-value="main">
                            <svg t="1721818018091" className={styles.icon} viewBox="0 0 1111 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3758" >
                                <path d="M0 555.885714L555.885714 0l555.885715 555.885714h-146.285715v468.114286H146.285714V555.885714z" fill="#c1acd7" p-id="3759" data-spm-anchor-id="a313x.search_index.0.i2.553c3a816b7sQk" ></path>
                                <path d="M409.6 1024V614.4h292.571429v409.6z" fill="#915fc7" p-id="3760" data-spm-anchor-id="a313x.search_index.0.i3.553c3a816b7sQk" ></path>
                            </svg>
                        </div>
                    </div>
                    <div className={styles.employment} style={{ gridColumn: "1", gridRow: "1" }} data-value={0} onClick={(e) => turnside(e)}>
                        <div className={styles.turnSide} ref={(e) => frontRef.current[0] = (e)}>
                            <h1>UWise </h1>
                            <h2>Full Stack Developer</h2>
                            <div className={styles.bg}>
                                <img alt='bg' src={process.env.PUBLIC_URL + "img/Employment/工作/uwise.png"} />
                            </div>
                            {/* <div className={styles.decorate_bg}></div> */}
                        </div>
                        <div className={styles.turnSide} ref={(e) => backRef.current[0] = (e)}>
                            <h3>Startup Company: UWise | Hybrid/ Seattle, WA</h3>
                            <h3>Full Stack Developer | November.2023 - September 2024</h3>
                            <ul>
                                <li><strong>Role: </strong>Responsible for dynamic resource web page deployment to help freshmen get familiar with the University of Washington (UW) and Seattle quickly.</li>
                                <li><strong>Technologies Used: </strong>Javascript, React, Node.js, NoSQL (MongoDB)</li>
                                <li><strong>Project Highlights: </strong>
                                    <ul>
                                        <li>Successfully designed and implemented the web platform <a data-value={0} href='https://uwise.netlify.app/'>UWise</a>, which serves as a comprehensive resource for incoming UW students.</li>
                                        <li>Developed and deployed dynamic resources allowing for real-time updates and easy navigation of important information about UW and Seattle.</li>
                                    </ul>
                                </li>
                                <li><strong>Collaboration: </strong>Worked closely with team members responsible for static resources to integrate both dynamic and static content seamlessly.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.employment} style={{ gridColumn: "2", gridRow: "2" }} data-value={1} onClick={(e) => turnside(e)}>
                        <div className={styles.turnSide} ref={(e) => frontRef.current[1] = (e)}>
                            <h1>China Unicom</h1>
                            <h2>Full Stack Developer</h2>
                            <div className={styles.bg}>
                                <img alt='bg' src={process.env.PUBLIC_URL + "img/Employment/工作/联通工作流.png"} />
                            </div>
                            {/* <div className={styles.decorate_bg}></div> */}
                        </div>
                        <div className={styles.turnSide} ref={(e) => backRef.current[1] = (e)}>
                            <h3>China Unicom | On-site/ Jinan, Shandong</h3>
                            <h3>Full Stack Developer Intern |  June.2023 - May.2023</h3>
                            <ul>
                                <li><strong>Role: </strong>Contributed to the development of the Workflow Engine Platform (internal application, not publicly accessible).</li>
                                <li><strong>Technologies Used: </strong>Java, MyBatis, Spring Boot, MySQL (Navicat), Redis</li>
                                <li><strong>Project Highlights: </strong>
                                    <ul>
                                        <li>Enhanced the platform's performance and reliability by testing and optimization.</li>
                                        <li>Programmed log records related to flow data (XML) operations through JDBC.</li>
                                        <li>Evaluated database rules, specifically Redis downtime rules, and reported findings to colleagues for improvement.</li>
                                    </ul>
                                </li>
                                <li><strong>Collaboration: </strong>Worked closely with colleagues to report and address bugs and performance issues.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.employment} style={{ gridColumn: "1", gridRow: "3" }} data-value={2} onClick={(e) => turnside(e)}>
                        <div className={styles.turnSide} ref={(e) => frontRef.current[2] = (e)}>
                            <h1>Beats by Dre</h1>
                            <h2>Data Engineer</h2>
                            <div className={styles.bg}>
                                <img alt='bg' src={process.env.PUBLIC_URL + "img/Employment/工作/Beats.png"} />
                            </div>
                            {/* <div className={styles.decorate_bg}></div> */}
                        </div>
                        <div className={styles.turnSide} ref={(e) => backRef.current[2] = (e)}>
                            <h3>Beats by Dre | Remote/ U.S.</h3>
                            <h3>Data Engineer Intern | June.2022 - August.2022</h3>
                            <ul>
                                <li><strong>Role: </strong>Managed advertisement data into the database and analyzed the effects of different advertisements.</li>
                                <li><strong>Technologies Used: </strong>MySQL (MySQL Workbench), R</li>
                                <li><strong>Project Highlights: </strong>
                                    <ul>
                                        <li>Efficiently stored and managed sales and advertisement data in a MySQL database.</li>
                                        <li>Conducted comprehensive data analysis to evaluate the effectiveness of various advertisements.</li>
                                        <li>Contributed to the development of new marketing strategies based on data-driven insights.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.employment} style={{ gridColumn: "2", gridRow: "4" }} data-value={3} onClick={(e) => turnside(e)}>
                        <div className={styles.turnSide} ref={(e) => frontRef.current[3] = (e)}>
                            <h1>Minecraft Game Map Studio</h1>
                            <h2>Designer</h2>
                            <div className={styles.bg}>
                                <img alt='bg' src={process.env.PUBLIC_URL + "img/Employment/工作/多玩.png"} />
                            </div>
                            {/* <div className={styles.decorate_bg}></div> */}
                        </div>
                        <div className={styles.turnSide} ref={(e) => backRef.current[3] = (e)}>
                            <h3>Startup: Minecraft Game Map Studio Hybrid/ Jinan, Shandong</h3>
                            <h3>Designer | June.2017 - June.2020</h3>
                            <ul>
                                <li><strong>Role: </strong>Designed two Minecraft game maps independently, achieving 4 million downloads all over the internet and gaining 121,000 fans.</li>
                                <li><strong>Technologies Used: </strong>Java, Minecraft game engine, Graphic design software</li>
                                <li><strong>Project Highlights: </strong>
                                    <ul>
                                        <li>Successfully designed and built popular game maps with a significant download and fan base.</li>
                                        <li>Created promotional posters to attract and engage users.</li>
                                        <li>Improved user ratings by actively responding to feedback and enhancing gameplay experience.</li>
                                    </ul>
                                </li>
                                <li><strong>Collaboration: </strong>Worked closely with team members to coordinate map development and promotional activities.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer />
            </main >
            <MenuS changePage={(e) => changePage(e)} />
            <Bg />
        </div >
    );
}