import React, { useEffect, useRef, useState } from 'react'; //import React Component
import { useNavigate } from "react-router-dom";
import styles from './Projects.module.css';
import { Bg } from '../../Components/Bg/Bg.js';
import { Footer } from '../../Components/Footer/Footer.js';
import { MenuS } from '../../Components/Menu/MenuS.js';
import projectsIntroList from '../../Data/projectsInfo.json';

export function Projects() {
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    //------
    //切换project
    //------
    //------
    //切换
    const navigate = useNavigate();
    const [page, setPage] = useState(1) //用于3d效果
    const projectsIntroListRef = useRef(null)
    const projectsIntro_containerRef = useRef([])
    const [projectsIntroListWidth, setProjectsIntroListWidth] = useState(750);
    const [click, setClick] = useState(false)
    const updateCardWidth = () => {
        setTimeout(() => {
            if (projectsIntroListRef.current) {
                setProjectsIntroListWidth(projectsIntroListRef.current.offsetWidth);
            }
        }, 300);
    };

    useEffect(() => {
        updateCardWidth();
        window.addEventListener('resize', updateCardWidth);
        return () => {
            window.removeEventListener('resize', updateCardWidth);
        };
    });

    const switchLeft = (e) => {
        e.stopPropagation();
        if (!click) {
            setClick(true);
            const projectsIntroList = projectsIntroListRef.current;
            const totalProjectsIntro = projectsIntroList.children.length;
            let currentLeft = projectsIntroList.offsetLeft;
            let newPosition = currentLeft + projectsIntroListWidth;
            if (newPosition > 0) {
                newPosition = -(projectsIntroListWidth * (totalProjectsIntro - 1));
                setPage(9)
            } else {
                setPage(page - 1)
            }

            projectsIntroList.style.left = `${newPosition}px`;
            setTimeout(() => {
                setClick(false);
            }, 300)
        }
    };

    const switchRight = (e) => {
        e.stopPropagation();
        if (!click) {
            setClick(true);
            const projectsIntroList = projectsIntroListRef.current;
            const totalProjectsIntro = projectsIntroList.children.length;
            let currentLeft = projectsIntroList.offsetLeft;
            let newPosition = currentLeft - projectsIntroListWidth;
            if (newPosition < -(projectsIntroListWidth * (totalProjectsIntro - 1))) {
                newPosition = 0;
                setPage(1)
            } else {
                setPage(page + 1)
            }

            projectsIntroList.style.left = `${newPosition}px`;
            setTimeout(() => {
                setClick(false);
            }, 300)
        }
    };

    const [isDragging, setIsDragging] = useState(false);
    const [mousePosition, setMousePosition] = useState(0);
    const [touchPosition, setTouchPosition] = useState(0)
    const [mouse, setMouse] = useState(0)
    const [switchPage, setSwitchpage] = useState(false) //是否需要向左切换到下一页
    const [switchPage2, setSwitchpage2] = useState(false) //是否需要向右切换到上一页
    const [keepPage, setKeepPage] = useState(false) //向左拖拽幻灯片的距离不足, 保持当前幻灯片页面
    const [keepPage2, setKeepPage2] = useState(false) //向右拖拽幻灯片的距离不足, 保持当前幻灯片页面
    const [end, setEnd] = useState(false) //判断是否到头
    const mouseDown = (e) => {
        // e.stopPropagation();
        const projectsIntroList = projectsIntroListRef.current;
        setClick(true)
        setIsDragging(true);
        if (e.touches) {
            const initialTouch = e.touches[0];
            setTouchPosition(initialTouch.clientX - projectsIntroList.offsetLeft)
        } else {
            setMouse(e.nativeEvent.clientX);
            setMousePosition(e.nativeEvent.clientX);
        }
        projectsIntroList.style.cursor = "grabbing";
        projectsIntroList.style.transition = "none";
    };

    const mouseMove = (e) => {
        e.stopPropagation();
        const projectsIntroList = projectsIntroListRef.current;
        if (isDragging) {
            let constantD;
            let currentPosition;
            let distance; //这个distance用来判断一次移动是否可以切换
            let newPosition;

            if (e.touches) {
                constantD = 20
                currentPosition = e.touches[0].clientX;

                distance = mouse - currentPosition;
                newPosition = currentPosition - touchPosition
            } else {
                constantD = 80
                currentPosition = e.nativeEvent.clientX;
                setMousePosition(currentPosition);

                distance = mouse - currentPosition;
                newPosition = projectsIntroList.offsetLeft - (mousePosition - currentPosition) / 3;
            }
            projectsIntroList.style.left = `${newPosition}px`;

            let end = false
            if (projectsIntroList.offsetLeft < -((projectsIntroList.children.length - 1) * projectsIntroListWidth)) {
                end = true
                setEnd(end)
            }

            // 设置是否需要切换页面的状态
            if (!end) {
                if (distance > 0) { //左滑 mouse正
                    if (projectsIntro_containerRef.current[page]) {
                        projectsIntro_containerRef.current[page].style.transform = "translateZ(-500px) rotateY(30deg)";
                    }
                    if (projectsIntro_containerRef.current[page - 2]) {
                        projectsIntro_containerRef.current[page - 2].style.transform = "translateZ(-800px) rotateY(-50deg)";
                    }
                    if (distance > constantD) {
                        setSwitchpage(true)
                        setKeepPage(false)
                    } else if (distance <= constantD && distance >= 0) {
                        setKeepPage(true)
                        setSwitchpage(false)
                    }
                } else { //右滑 mouse负
                    if (projectsIntro_containerRef.current[page - 2]) {
                        projectsIntro_containerRef.current[page - 2].style.transform = "translateZ(-500px) rotateY(-30deg)";
                    }
                    if (projectsIntro_containerRef.current[page]) {
                        projectsIntro_containerRef.current[page].style.transform = "translateZ(-800px) rotateY(50deg)";
                    }
                    if (distance < -constantD) {
                        setSwitchpage2(true)
                        setKeepPage2(false)
                    }
                    else if (distance > -constantD && distance < 0) {
                        setKeepPage2(true)
                        setSwitchpage2(false)
                    }
                }
            }
        }
    };

    const mouseUp = (e) => {
        e.stopPropagation();
        setClick(false);
        setIsDragging(false);
        const projectsIntroList = projectsIntroListRef.current;
        projectsIntroList.style.cursor = "grab";
        const currentLeft = projectsIntroList.offsetLeft
        const totalProjectsIntro = projectsIntroList.children.length;
        projectsIntroList.style.transition = "0.3s";

        let newPosition = 0
        if (!end) {
            for (let i = 0; i < totalProjectsIntro; i++) {
                if (switchPage && currentLeft > -projectsIntroListWidth * (i + 1) && currentLeft <= -projectsIntroListWidth * i) {
                    newPosition = -projectsIntroListWidth * (i + 1)
                    setPage(i + 2)
                }
                if (keepPage && currentLeft > -projectsIntroListWidth * (i + 1) && currentLeft <= -projectsIntroListWidth * i) {
                    newPosition = -projectsIntroListWidth * i
                }
                if (switchPage2 && currentLeft < -projectsIntroListWidth * i && currentLeft >= -projectsIntroListWidth * (i + 1)) {
                    newPosition = -projectsIntroListWidth * (i)
                    setPage(i + 1)
                }
                if (keepPage2 && currentLeft < -projectsIntroListWidth * i && currentLeft >= -projectsIntroListWidth * (i + 1)) {
                    newPosition = -projectsIntroListWidth * (i + 1)
                }
            }
        } else {
            newPosition = -projectsIntroListWidth * (totalProjectsIntro - 1)
            setEnd(false)
        }

        projectsIntroList.style.left = `${newPosition}px`;
        setSwitchpage(false)
        setSwitchpage2(false)
    };

    const mouseLeave = (e) => {
        e.stopPropagation();
        setIsDragging(false);
        const projectsIntroList = projectsIntroListRef.current;
        projectsIntroList.style.cursor = "grab";
        const currentLeft = Math.abs(projectsIntroList.offsetLeft);
        let slideIndex = Math.round(currentLeft / projectsIntroListWidth);

        projectsIntroList.style.transition = "0.3s";
        projectsIntroList.style.left = `${-(slideIndex * projectsIntroListWidth)}px`;
        setPage(slideIndex + 1)
    };

    //3d
    useEffect(() => {
        if (projectsIntro_containerRef.current[page - 1]) {
            const projectsIntroList = projectsIntroListRef.current;
            const totalProjectsIntro = projectsIntroList.children.length;

            projectsIntro_containerRef.current.forEach((projectsIntro_container) => {
                projectsIntro_container.style.transform = "none";
            });

            if (page !== totalProjectsIntro) {
                projectsIntro_containerRef.current[page].style.transform = "translateZ(-300px) rotateY(20deg)";
            } else {
                projectsIntro_containerRef.current[page - 2].style.transform = "translateZ(-300px) rotateY(-20deg)";
            }

            if (page !== 1) {
                projectsIntro_containerRef.current[page - 2].style.transform = "translateZ(-300px) rotateY(-20deg)";
            } else {
                projectsIntro_containerRef.current[page].style.transform = "translateZ(-300px) rotateY(20deg)";
            }
        }
    }, [page, projectsIntro_containerRef.current[page - 1], isDragging]);

    //进入projectDetail
    const mainRef = useRef(null)
    const titleRef = useRef(null)
    const decorateRef = useRef(null)
    const pageRef = useRef(null)
    const nextRef = useRef(null)
    const prevRef = useRef(null)
    const projectDetail = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        let { page } = e.target.dataset;
        let { ifdetail } = e.target.dataset;
        let { url } = e.target.dataset;
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"
        e.target.style.pointerEvents = "none"
        decorateRef.current.style.transition = "0.7s"
        decorateRef.current.style.top = "-50px"
        pageRef.current.style.transition = "0.7s"
        pageRef.current.style.bottom = "-80px"
        nextRef.current.style.opacity = "0"
        prevRef.current.style.opacity = "0"
        projectsIntroListRef.current.style.pointerEvents = "none"

        projectsIntro_containerRef.current.forEach((projectsIntro_container, index) => {
            if (index + 1 !== parseInt(page)) {
                projectsIntro_container.style.opacity = "0"
            } else {
                projectsIntro_container.querySelectorAll('*').forEach((child) => {
                    child.style.opacity = "0";
                });
                projectsIntro_container.style.transition = "1s"
                projectsIntro_container.style.width = "30vmax"
                projectsIntro_container.style.height = "85px"

                setTimeout(() => {
                    projectsIntro_container.style.transform = "translateY(-100vh)"
                }, 300);
            }
        })

        setTimeout(() => {
            topFunction()
            if (ifdetail === "true") {
                navigate(`/Projects/${value}`)
            } else {
                window.location.href = `${url}`
            }
        }, 1000);
    }

    //菜单三件套
    //click menu后设置部分变量
    const [menu, setMenu] = useState(false)
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const menuRef = useRef([])
    useEffect(() => {
        if (menu) {
            menuRef.current.style.opacity = "1"
            menuRef.current.style.pointerEvents = "all"
        } else {
            menuRef.current.style.opacity = "0"
            menuRef.current.style.pointerEvents = "none"
        }
    }, [menu])

    //切换页面
    const changePage = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        mainRef.current.style.opacity = "0"
        decorateRef.current.style.opacity = "0"
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"

        setTimeout(() => {
            topFunction()
            navigate(`/${value}`)
        }, 400);
    }

    const [projectsIntro, setProjectsIntro] = useState([])
    useEffect(() => {
        let projectsIntro = projectsIntroList.map((projectsIntro, index) => {
            let detail = true
            if (projectsIntro.highLightFeature.length <= 0) {
                detail = false
            }
            return (
                <div className={styles.threeD} key={projectsIntro.name}>
                    <div
                        className={`${styles.projectsIntro_container} color_change2`}
                        ref={(e) => projectsIntro_containerRef.current[index] = e}>
                        {/* <div className={styles.forBlur}></div> */}
                        <div className={styles.projectsIntro} data-value={projectsIntro.name}>
                            <h1 style={{ gridColumn: "1/span 2", gridRow: "1" }}>{projectsIntro.name}</h1>
                            <div style={{ gridColumn: "1", gridRow: "2" }}>
                                <div ><strong>Intro: </strong>{projectsIntro.intro}</div>
                            </div>
                            <div style={{ gridColumn: "1", gridRow: "3" }} >
                                <div><strong>Technique: </strong>{projectsIntro.technique}</div>
                            </div>
                            <div style={{ gridColumn: "2", gridRow: "2", display: "flex", justifyContent: "center" }} >
                                <img style={{ height: projectsIntro.bestDevice === "phone" ? "250px" : "auto", width: projectsIntro.bestDevice === "phone" ? "auto" : "250px" }} alt='gif' src={process.env.PUBLIC_URL + projectsIntro.gif} />
                            </div>
                            {projectsIntro.url ?
                                <div
                                    className={styles.detail}
                                    onClick={(e) => projectDetail(e)}
                                    data-value={projectsIntro.name}
                                    data-page={index + 1}
                                    data-ifdetail={detail}
                                    data-url={projectsIntro.url}

                                >More Info</div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>

            )
        })
        setProjectsIntro(projectsIntro)
    }, [projectsIntroList])

    return (
        <div id={styles.Projects}>
            <div className={styles.title} ref={titleRef}>
                <h1 className='color_change2'>
                    Projects
                </h1>
            </div>
            <div className={styles.bgFrame}></div>
            <div className={styles.bgFrame2}></div>
            {/* <div className={styles.bgFrame3}></div> */}
            {/* <div className={styles.bgFrame4}></div> */}
            <main ref={mainRef}>
                <div className={styles.decorate} ref={decorateRef}>
                    <div onClick={openMenu}>Menu</div>
                </div>

                <div className={styles.menu} ref={menuRef}>
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="main" style={{ textAlign: "center" }}>
                        <svg t="1721818018091" className={styles.icon} viewBox="0 0 1111 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3758" >
                            <path d="M0 555.885714L555.885714 0l555.885715 555.885714h-146.285715v468.114286H146.285714V555.885714z" fill="#c1acd7" p-id="3759" data-spm-anchor-id="a313x.search_index.0.i2.553c3a816b7sQk" ></path>
                            <path d="M409.6 1024V614.4h292.571429v409.6z" fill="#915fc7" p-id="3760" data-spm-anchor-id="a313x.search_index.0.i3.553c3a816b7sQk" ></path>
                        </svg>
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Employment">
                        Employment
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Research">
                        Research
                    </div>
                </div>

                <div className={styles.projectsIntroList_container}>
                    <div
                        className={styles.projectsIntroList}
                        style={{ gridTemplateColumns: `repeat(${projectsIntro.length}, 100%)` }}
                        ref={projectsIntroListRef}
                        onMouseDown={(e) => mouseDown(e)}
                        onMouseMove={(e) => mouseMove(e)}
                        onMouseUp={(e) => mouseUp(e)}
                        onMouseLeave={(e) => mouseLeave(e)}
                        onTouchStart={(e) => mouseDown(e)}
                        onTouchMove={(e) => mouseMove(e)}
                        onTouchEnd={(e) => mouseUp(e)}
                        onTouchCancel={(e) => mouseLeave(e)}>
                        {projectsIntro}
                    </div>
                    <div className={styles.prev} onClick={(e) => switchLeft(e)} ref={prevRef}>❮</div>
                    <div className={styles.next} onClick={(e) => switchRight(e)} ref={nextRef}>❯</div>
                </div>
                <h1 className={styles.page} ref={pageRef}>{page} / {projectsIntroListRef.current ? projectsIntroListRef.current.children.length : null}</h1>
            </main >

            <MenuS changePage={(e) => changePage(e)} />
            <Bg />
            <Footer />
        </div >
    )
}