import React, { useEffect, useRef, useState } from 'react'; //import React Component
import styles from './Research.module.css';
import { Bg } from '../../Components/Bg/Bg.js';
import { Footer } from '../../Components/Footer/Footer.js';
import { MenuS } from '../../Components/Menu/MenuS.js';

export function Research() {
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    //菜单三件套
    //click menu后设置部分变量
    const [menu, setMenu] = useState(false)
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const menuRef = useRef([])
    useEffect(() => {
        if (menu) {
            menuRef.current.style.opacity = "1"
            menuRef.current.style.pointerEvents = "all"
        } else {
            menuRef.current.style.opacity = "0"
            menuRef.current.style.pointerEvents = "none"
        }
    }, [menu])

    //切换页面
    const mainRef = useRef(null)
    const titleRef = useRef(null)
    const decorateRef = useRef(null)
    const changePage = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        mainRef.current.style.opacity = "0"
        decorateRef.current.style.opacity = "0"
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"

        setTimeout(() => {
            topFunction()
            window.location.href = `/${value}`
        }, 400);
    }

    //放大div
    const infoRef = useRef([])
    const img_containerRef = useRef([])
    const textRef = useRef([])
    const [ifChoose, setIfChoose] = useState(false)
    const enlarge = (e) => {
        let { value } = e.target.dataset
        if (!ifChoose) {
            infoRef.current.forEach((info, index) => {
                if (index !== parseInt(value)) {
                    info.style.opacity = "0"
                    info.style.margin = "80px"
                } else {
                    setTimeout(() => {
                        info.style.position = "fixed"
                        info.style.top = "calc(50% + 50px)"
                        info.style.transform = "translate(-50%, -50%) scale(1.25)"
                        info.style.width = "99vw"
                        info.style.maxWidth = "600px"
                        img_containerRef.current[index].style.height = "0"
                        img_containerRef.current[index].style.border = "none"
                        textRef.current[index].style.transition = "1.5s"
                        textRef.current[index].style.opacity = "1"
                    }, 300);
                }
            })
        } else {
            infoRef.current.forEach((info, index) => {
                info.style.opacity = "1"
                info.style.position = "relative"
                info.style.removeProperty('transform')
                info.style.removeProperty('top')
                info.style.removeProperty('margin')
                info.style.width = "100%"
                info.style.removeProperty('maxWidth')
                img_containerRef.current[index].style.height = "100%"
                img_containerRef.current[index].style.border = "4px double white"
                textRef.current[index].style.transition = "0.3s"
                textRef.current[index].style.opacity = "0"
            })
        }
        setIfChoose(!ifChoose)
    }

    const [height, setHeight] = useState(1078)
    useEffect(() => {
        if (mainRef.current) {
            setTimeout(() => {
                setHeight(mainRef.current.offsetHeight * 0.8)
            }, 300);
        }
    }, [ifChoose])

    //animation-timeline
    const [scrollPercentage, setScrollPercentage] = useState(0)
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolled = (scrollTop / (scrollHeight - clientHeight)).toFixed(2);

        setScrollPercentage(scrolled);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const bgFrameRef = useRef(null);
    const bgFrame2Ref = useRef(null);
    useEffect(() => {
        bgFrameRef.current.style.bottom = `${-50 * (1 - scrollPercentage)}vh`
        bgFrame2Ref.current.style.top = `${-50 * (scrollPercentage)}vh`
    }, [scrollPercentage])
    return (
        <div id={styles.Research} style={{ "--h": `${height}px` }}>
            <div className={styles.title} ref={titleRef}>
                <h1 className="color_change2">
                    Research
                </h1>
            </div>
            <div className={styles.bgFrame} ref={bgFrameRef}></div>
            <div className={styles.bgFrame2} ref={bgFrame2Ref}></div>
            <main ref={mainRef}>
                <div className={styles.decorate} ref={decorateRef}>
                    <div onClick={openMenu}>Menu</div>
                </div>

                <div className={styles.menu} ref={menuRef}>
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="main" style={{ textAlign: "center" }}>
                        <svg t="1721818018091" className={styles.icon} viewBox="0 0 1111 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3758" >
                            <path d="M0 555.885714L555.885714 0l555.885715 555.885714h-146.285715v468.114286H146.285714V555.885714z" fill="#c1acd7" p-id="3759" data-spm-anchor-id="a313x.search_index.0.i2.553c3a816b7sQk" ></path>
                            <path d="M409.6 1024V614.4h292.571429v409.6z" fill="#915fc7" p-id="3760" data-spm-anchor-id="a313x.search_index.0.i3.553c3a816b7sQk" ></path>
                        </svg>
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Employment">
                        Employment
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Projects">
                        Projects
                    </div>
                </div>

                <div className={styles.info_container}>
                    <div className={styles.info} ref={(e) => infoRef.current[0] = e} data-value={0} onClick={(e) => enlarge(e)}>
                        <div className={styles.detail}>
                            <div className={styles.img_container} ref={(e) => img_containerRef.current[0] = e}></div>
                            <div className={styles.text} ref={(e) => textRef.current[0] = e}>
                                <div>
                                    <strong>Introduction: </strong>In my research, I conducted essential operations involving the use of virtual machines to create databases.
                                    This process was complemented by analyzing the metadata embedded in multimedia files.
                                    By leveraging the analytical capabilities of ChatGPT, I was able to dissect and interpret complex datasets effectively.
                                    This deep dive into data analytics not only enabled me to examine the intricacies of file operations but also provided a deeper understanding of the transformations that data undergoes during file manipulations.
                                </div>
                                <br />
                                <div><strong>Paper: </strong>Researching ChatGPT's ability to generate code for handling complex data</div>
                            </div>
                        </div>
                        <h2>
                            Chatgpt Analysis Complex Data<br />(May.2023 - January.2024)
                        </h2>
                    </div>

                    <div className={styles.info} ref={(e) => infoRef.current[1] = e} data-value={1} onClick={(e) => enlarge(e)}>
                        <div className={styles.detail}>
                            <div className={styles.img_container} ref={(e) => img_containerRef.current[1] = e}></div>
                            <div className={styles.text} ref={(e) => textRef.current[1] = e}>
                                <div>
                                    In my study of Human-Computer Interaction (HCI),
                                    I independently chose to develop a game recommendation website as my Capstone Project.
                                    After completing the project, I collected data from the website to formulate the topic of my paper.
                                    I then analyzed the collected data and created visualizations to present the findings.
                                    The final paper was published, showcasing the insights gained from this research.
                                    This research mainly explores the impact of interactive interface design on user choices.
                                </div>
                                <br />
                                <div><strong>Paper: </strong>The Impact of using Folksonomy on Subject Indexing usage Rate and Data Storage in Social Entertainment Websites</div>
                            </div>
                        </div>
                        <h2>
                            Inclusive Web Design <br />(2023.1 - 2023.3)
                        </h2>
                    </div>
                </div>

                {/* <div className={styles.closeMenu} onClick={openMenu} ref={closeMenuRef}></div>
                <div className={styles.menu_container}>
                    <div className={styles.submenu} onClick={openMenu}>
                        <svg t="1722106080757" className={styles.icon} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5574">
                            <path d="M76.83347 382.604066L404.834786 54.650852c56.89557-56.887553 157.619247-56.791351 214.434647 0.032068l327.905114 327.93718c59.180371 59.236489 59.180371 155.230226-0.016034 214.434648-59.252523 59.140287-155.190142 59.188388-214.410597-0.032068L663.666737 527.909435v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-83.743993 0-151.590577-67.878651-151.590577-151.614627V527.8453l-69.217465 69.209448a151.326021 151.326021 0 0 1-107.209307 44.373253c-38.76146 0-77.603088-14.815135-107.201291-44.405321-59.188388-59.228472-59.188388-155.182125 0.008017-214.418614z" fill="#FFFFFF" p-id="5575"></path>
                            <path d="M510.087982 12.121474c40.060189-0.472994 80.288732 13.668726 109.173435 42.561446l327.905113 327.93718c59.180371 59.236489 59.180371 155.230226-0.016034 214.434648-59.252523 59.140287-155.190142 59.188388-214.410597-0.032068l-69.081179-69.113245v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-0.657382 0-1.290713-0.096202-1.948094-0.096202V12.121474z" fill="#E9F1F4" p-id="5576"></path>
                            <path d="M291.228034 597.054748a151.221802 151.221802 0 0 1-182.719998 24.050544l296.32675-296.294682c56.89557-56.855485 157.619247-56.791351 214.434647 0.048101l296.214514 296.246581c-57.96181 33.366121-133.215962 25.461509-182.736031-24.082612L663.666737 527.909435v332.386531c0 83.735976-67.894685 151.614628-151.622644 151.614627-83.743993 0-151.590577-67.878651-151.590577-151.614627V527.8453l-69.225482 69.209448z" fill="#D8E5EA" p-id="5577"></path>
                            <path d="M511.995992 282.20908v729.701513c-83.72796-0.016034-151.550493-67.894685-151.550493-151.614627V527.8453l-69.217465 69.209448a151.221802 151.221802 0 0 1-182.719998 24.050544l296.32675-296.294682c28.419726-28.403692 67.798483-42.60153 107.161206-42.60153z" fill="#E9F1F4" p-id="5578"></path>
                            <path d="M512.036076 1023.935865c-90.213589 0-163.615849-73.410276-163.615849-163.639899V556.874306l-48.694334 48.678301c-30.961067 30.896932-72.047412 47.900666-115.715183 47.900666a162.565642 162.565642 0 0 1-115.707166-47.932734c-63.749975-63.814109-63.741958-167.624273 0.016034-231.422348L396.32891 46.144977C425.662556 16.819347 467.82316 0 512.012025 0h0.072152c44.196883 0.016034 86.357486 16.851414 115.683115 46.177044l327.905113 327.93718c63.774025 63.830143 63.766008 167.65634-0.016033 231.438383-63.854194 63.741958-167.664357 63.70989-231.414332-0.032068l-48.558048-48.582098v303.349508c0 90.23764-73.410276 163.647916-163.647916 163.647916zM360.445499 515.820028a12.017255 12.017255 0 0 1 12.025272 12.025272v332.450666c0 76.969757 62.611582 139.589356 139.565305 139.589355 76.977774 0 139.597373-62.627616 139.597372-139.589355V527.909435a12.017255 12.017255 0 1 1 20.531148-8.505876l69.081178 69.113246c54.378279 54.370263 142.940398 54.394313 197.41488 0.02405 54.40233-54.40233 54.410347-142.972466 0.008017-197.422896L610.763558 63.180778c-24.852229-24.852229-60.823825-39.114201-98.695415-39.130234h-0.056118c-37.863573 0-73.819136 14.245939-98.679381 39.098167L85.339346 391.109942c-54.394313 54.42638-54.40233 142.988499-0.024051 197.41488a138.691469 138.691469 0 0 0 98.695415 40.885924c37.246275 0 72.311968-14.518512 98.719465-40.861874l69.209448-69.201431a12.025272 12.025272 0 0 1 8.505876-3.527413z" fill="#472968" p-id="5579" data-spm-anchor-id="a313x.search_index.0.i9.7c2b3a81wMgrnZ" ></path>
                            <path d="M664.516523 539.934707c-3.07847 0-6.156939-1.17046-8.505876-3.519397l-72.913231-72.86513a12.025272 12.025272 0 1 1 17.011751-17.011752l72.913232 72.873148a12.025272 12.025272 0 0 1-8.505876 20.523131zM359.467443 539.934707a12.033289 12.033289 0 0 1-8.505875-20.523131l72.897198-72.873148a12.041306 12.041306 0 0 1 17.011751 0 12.041306 12.041306 0 0 1 0 17.011752l-72.897198 72.86513a11.985188 11.985188 0 0 1-8.505876 3.519397z" fill="#33363A" p-id="5580"></path>
                            <path d="M550.12412 708.480917c-3.07847 0-6.156939-1.17046-8.505875-3.519396l-29.622253-29.63027-29.622253 29.63027a12.025272 12.025272 0 0 1-17.011752-17.003735l38.128129-38.144162c4.513485-4.513485 12.498266-4.513485 17.011751 0l38.128129 38.144162a12.033289 12.033289 0 0 1-8.505876 20.523131z" fill="#472968" p-id="5590" data-spm-anchor-id="a313x.search_index.0.i17.7c2b3a81wMgrnZ" className="selected"></path>
                            <path d="M511.987975 756.405634a12.025272 12.025272 0 0 1-12.025272-12.025272V658.311483a12.025272 12.025272 0 1 1 24.050544 0v86.068879a12.025272 12.025272 0 0 1-12.025272 12.025272zM511.995992 792.561618a12.017255 12.017255 0 0 1-12.025272-12.025272c0-3.206739 1.282696-6.253141 3.527413-8.505876 4.489435-4.489435 12.506283-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531148zM511.995992 828.637433a12.017255 12.017255 0 0 1-12.025272-12.025272c0-3.206739 1.282696-6.253141 3.527413-8.505875 4.489435-4.489435 12.506283-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531147zM511.995992 864.713249c-3.126571 0-6.253141-1.282696-8.497859-3.527413a12.001221 12.001221 0 0 1 0-17.003735c4.489435-4.489435 12.586451-4.489435 16.995717 0a12.025272 12.025272 0 0 1-8.497858 20.531148z" fill="#472968" p-id="5591" data-spm-anchor-id="a313x.search_index.0.i14.7c2b3a81wMgrnZ" className="selected"></path>
                        </svg>
                    </div>
                    <div className={styles.menu} ref={menuRef} onClick={openMenu}>
                        <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="main" style={{ textAlign: "center" }}>
                            <svg t="1721818018091" className={styles.icon} viewBox="0 0 1111 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3758" >
                                <path d="M0 555.885714L555.885714 0l555.885715 555.885714h-146.285715v468.114286H146.285714V555.885714z" fill="#c1acd7" p-id="3759" data-spm-anchor-id="a313x.search_index.0.i2.553c3a816b7sQk" ></path>
                                <path d="M409.6 1024V614.4h292.571429v409.6z" fill="#915fc7" p-id="3760" data-spm-anchor-id="a313x.search_index.0.i3.553c3a816b7sQk" ></path>
                            </svg>
                        </div>
                        <hr style={{ width: "100%" }} />
                        <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Employment">
                            Employment
                        </div>
                        <hr style={{ width: "100%" }} />
                        <div className={styles.menuButton} onClick={(e) => changePage(e)} data-value="Projects">
                            Projects
                        </div>
                    </div>
                </div> */}

                <Footer position={ifChoose} />
            </main>
            <MenuS changePage={(e) => changePage(e)} />
            <Bg />
        </div>
    );
}