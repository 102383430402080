import React, { useEffect, useRef, useState, useCallback } from 'react'; //import React Component
import { useNavigate } from "react-router-dom";
import styles from './Main.module.css';
import { Bg } from '../../Components/Bg/Bg.js';
import { Footer } from '../../Components/Footer/Footer.js';
import { ImgGallery } from '../../Components/ImgGallery/ImgGallery.js'
import imgs from '../../Data/imgSetSelf.json'

export function Main() {
    //回到页面最上面
    const topFunction = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };
    //------
    //小标题
    //------
    //------
    //跳动动画
    // const [textArray, setTextArray] = useState([])
    // useEffect(() => {
    //     const text = `Victoria's Portfolio`;
    //     let textArray = []
    //     for (let i = 0; i < text.length; i++) {
    //         const char = text.charAt(i);
    //         textArray.push(char)
    //     }

    //     let textArray_dom = textArray.map((letter, index) => {
    //         return (
    //             <span className={styles.bounce} style={{ animationDelay: `${index * 0.1}s` }} key={index}>
    //                 {letter !== ' ' ? letter : '\u00A0'}
    //             </span>
    //         )
    //     });
    //     setTextArray(textArray_dom)
    // }, []);

    //------
    //Header
    //------
    //------
    //设置页面scroll动画，＞7 header消失
    const headerRef = useRef(null);
    const h1Ref = useRef(null);
    const meImgRef = useRef(null);
    const tipRef = useRef(null);
    const [large, setLarge] = useState(false) //是否有放大的chunk
    const [changeHeader, setChangeHeader] = useState(false) //是否header缩小
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 7) {
                setChangeHeader(true)
                headerRef.current.style.height = "40px";
                headerRef.current.style.opacity = "0.3";
                headerRef.current.style.borderRadius = "0 0 40px 40px";
                meImgRef.current.style.opacity = "0";
                tipRef.current.style.opacity = "0";
                h1Ref.current.style.opacity = "0"
            } else if (!large && !switchPage) {
                setChangeHeader(false)
                headerRef.current.style.removeProperty('height');
                headerRef.current.style.opacity = "1";
                headerRef.current.style.borderRadius = "0";
                meImgRef.current.style.removeProperty('opacity');
                tipRef.current.style.removeProperty('opacity');
                h1Ref.current.style.removeProperty('opacity');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    })

    //header提示hover出现
    const tip2Ref = useRef(null);
    const showTip = () => {
        if (changeHeader && !large) {
            tip2Ref.current.style.opacity = "1"
        }
    }

    //header提示不hover消失
    const deleteTip = () => {
        tip2Ref.current.style.opacity = "0"
    }

    //只有header变小才会出现“pokepoke”的提示
    useEffect(() => {
        if (!changeHeader) {
            deleteTip()
        }
    }, [changeHeader])

    //点击缩小的header，放大header
    const scrollDown = () => {
        if (changeHeader && !large) {
            topFunction()
        }
    }

    //----------------
    //Info Chunk outer
    //----------------
    //----------------
    //hover chunk后设置部分变量
    const [leave, setLeave] = useState(true) //判断是否在chunk div里面（判断hover true or false）
    const [hover, setHover] = useState("") //判断hover的哪个chunk （判断hover内容）
    const showText = (e) => {
        let { value } = e.target.dataset
        setLeave(false)
        setHover(value)
    }

    //Hover chunk出现打印字体效果
    const showTextRef = useRef([]);
    useEffect(() => {
        let i = 0;
        let text = '';
        let speed = 50;
        let timeoutId;

        const typeWriter = (index, text) => {
            if (i < text.length && !leave) {
                showTextRef.current[index].innerHTML += text.charAt(i);
                i++;
                timeoutId = setTimeout(() => typeWriter(index, text), speed);
            }
        };

        if (!large && !leave) {
            if (hover === "basicInformation") {
                showTextRef.current[0].style.opacity = "1";
                text = 'My foundation!';
                typeWriter(0, text);
            }
            if (hover === "pictures") {
                showTextRef.current[1].style.opacity = "1";
                text = `My pictures!`;
                typeWriter(1, text);
            }
            if (hover === "education") {
                showTextRef.current[2].style.opacity = "1";
                text = `Education I have completed!`;
                typeWriter(2, text);
            }
            if (hover === "skill") {
                showTextRef.current[3].style.opacity = "1";
                text = 'What I am proficient in!';
                typeWriter(3, text);
            }
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [hover, large, leave])

    //设置随机五彩chunk颜色
    const infosRef = useRef(null);
    const infoRef = useRef([]);
    useEffect(() => {
        if (infoRef) {
            let colorset = ["rgba(193, 168, 196, 0.3)", "rgba(196, 174, 168, 0.3)", "rgba(196, 185, 168, 0.3)", "rgba(196, 196, 168, 0.3)", "rgba(169, 196, 168, 0.3)", "rgba(168, 196, 195, 0.3)", "rgba(168, 172, 196, 0.3)"]
            infoRef.current.forEach((info, index) => {
                let color = colorset[Math.floor(Math.random() * (colorset.length - index))];
                colorset = colorset.filter((color_used) => { return color_used !== color })
                info.style.backgroundColor = color
            });
        }
    }, [])

    //不hover或者click之后，打印字体消失
    const [choose, setChoose] = useState("") //判断click哪个chunk
    const deleteText = useCallback(() => {
        setLeave(true)
        if (hover === "basicInformation" && choose !== "basicInformation") {
            showTextRef.current[0].style.opacity = "0";
            showTextRef.current[0].innerHTML = null;
        }
        if (hover === "pictures" && choose !== "pictures") {
            showTextRef.current[1].style.opacity = "0";
            showTextRef.current[1].innerHTML = null;
        }
        if (hover === "education" && choose !== "education") {
            showTextRef.current[2].style.opacity = "0";
            showTextRef.current[2].innerHTML = null;
        }
        if (hover === "skill" && choose !== "skill") {
            showTextRef.current[3].style.opacity = "0";
            showTextRef.current[3].innerHTML = null;
        }
    }, [hover, choose])

    //放大chunk
    const enlarge = (e) => {
        let { value } = e.target.dataset

        deleteText()
        setChoose(value)
        if (!e.target.classList.contains('expanded') && e.target.classList.length !== 0) {
            // 若click的是小div，放大该div，缩小其他div
            setLarge(true)
            infosRef.current.style.display = "grid";
            infosRef.current.style.gridTemplateColumns = "70% 30%";
            infosRef.current.style.gridTemplateRows = "repeat(3, 24vh)";

            let currentSmallIndex = 0;
            infoRef.current.forEach((info) => { //设置小div
                if (info !== e.target) {
                    info.classList.remove("expanded");
                    info.style.gridColumn = "2";
                    info.style.gridRow = `${currentSmallIndex + 1}`; // 按顺序设置gridRow
                    info.style.width = "80%";
                    info.style.maxWidth = "450px";
                    info.style.height = "80%";
                    info.style.removeProperty('transition');
                    currentSmallIndex++;
                }
            });

            //设置大div
            e.target.classList.add("expanded");
            e.target.style.gridColumn = "1";
            e.target.style.gridRow = "1 / span 3";
            e.target.style.width = "60vw";
            e.target.style.maxWidth = "600px";
            e.target.style.height = "60vh";
            e.target.style.maxHeight = "550px";
            e.target.style.transition = "0.7s";
        } else {
            // 当点击已经是大div的元素时，重置所有div的样式
            setChoose("");
            setLarge(false);
            infoRef.current.forEach((info) => {
                let subValue = info.getAttribute('data-value')
                info.style.height = "30vh";
                info.classList.remove("expanded");
                if (subValue === "basicInformation") {
                    info.style.gridColumn = "1";
                    info.style.gridRow = "1";
                } else if (subValue === "pictures") {
                    info.style.gridColumn = "2";
                    info.style.gridRow = "1";
                } else if (subValue === "education") {
                    info.style.gridColumn = "1";
                    info.style.gridRow = "2";
                } else if (subValue === "skill") {
                    info.style.gridColumn = "2";
                    info.style.gridRow = "2";
                }
                info.style.width = "80%";
                info.style.height = "30vh";

                if (choose !== subValue) {
                    info.style.removeProperty('transition');
                }
            });
            infosRef.current.style.gridTemplateColumns = "repeat(2, 50%)";
            infosRef.current.style.gridTemplateRows = "repeat(2, 36vh)";
        }
    }

    //----------------
    //Info Chunk inner
    //----------------
    //----------------
    //设置ul高度（防止额外scroll）
    const ulRef = useRef(null)
    const h3Ref = useRef(null)
    useEffect(() => {
        if (choose !== "pictures" && ulRef.current && h3Ref.current) {
            const h3Height = h3Ref.current.offsetHeight
            const parentHeight = ulRef.current.parentElement.offsetHeight;
            ulRef.current.style.height = `${parentHeight - h3Height}px`;
        }
    }, [choose])

    //li颜色
    const skillLiRef = useRef([])
    // useEffect(() => {
    //     if (choose !== "pictures") {
    //         let colorset = [
    //             "rgb(160, 160, 233)",
    //             "rgb(233, 160, 160)",
    //             "rgb(221, 157, 104)",
    //             "rgb(179, 177, 79)",
    //             "rgb(122, 166, 202)",
    //             "rgb(122, 130, 202)",
    //             "rgb(143, 122, 202)",
    //             "rgb(179, 122, 202)",
    //             "rgb(199, 122, 202)",
    //             "rgb(202, 122, 169)",
    //             "rgb(202, 122, 139)",
    //             "rgb(221, 148, 159)",
    //         ];
    //         skillLiRef.current.forEach((li, index) => {
    //             let color = colorset[Math.floor(Math.random() * (colorset.length - index))];
    //             colorset = colorset.filter((color_used) => { return color_used !== color })
    //             if (li) {
    //                 li.style.color = color
    //             }
    //         });
    //     }
    // }, [choose])

    //----
    //Menu
    //----
    //----
    //小menu不会在有大div情况下出现，但是以及设置大menu可以
    const [menu, setMenu] = useState(false)
    const centerMenu_containerRef = useRef(null)
    useEffect(() => {
        if (!large && changeHeader) {
            centerMenu_containerRef.current.style.opacity = "1"
            centerMenu_containerRef.current.style.pointerEvents = "all"
        } else {
            if (!menu) {
                centerMenu_containerRef.current.style.opacity = "0"
                centerMenu_containerRef.current.style.pointerEvents = "none"
            }
        }
    }, [large, changeHeader, menu])

    //menu hover之后出现灯光跟随
    const centerMenuButton_containerRef = useRef(null)
    const moveLight = (e) => {
        e.stopPropagation();
        let distance = centerMenuButton_containerRef.current.getBoundingClientRect() //视口距离
        let x = e.clientX - distance.left;
        let y = e.clientY - distance.top;
        centerMenuButton_containerRef.current.style.setProperty("--x", x + "px")
        centerMenuButton_containerRef.current.style.setProperty("--y", y + "px")
    }

    //click menu后设置部分变量
    const openMenu = () => {
        setMenu(!menu)
    }

    //click menu后部分动效
    const centerMenuButtonRef = useRef(null)
    const circle_bgRef = useRef([])
    const circleRef = useRef(null)
    useEffect(() => {
        if (menu) {
            centerMenuButtonRef.current.style.transform = 'scale(1.2)';
            centerMenuButtonRef.current.style.width = '80px';
            centerMenuButtonRef.current.style.height = '80px';
            centerMenuButtonRef.current.style.color = 'rgb(174, 0, 255)';
            centerMenuButtonRef.current.style.border = '2px solid white';
            circle_bgRef.current.forEach((circle_bg) => {
                circle_bg.style.opacity = "1"
                circle_bg.style.pointerEvents = "all"
            })
            circleRef.current.style.opacity = "0"
        } else {
            centerMenuButtonRef.current.style.removeProperty("transform")
            centerMenuButtonRef.current.style.width = '60px';
            centerMenuButtonRef.current.style.height = '60px';
            centerMenuButtonRef.current.style.color = 'black';
            centerMenuButtonRef.current.style.border = 'none';
            circle_bgRef.current.forEach((circle_bg) => {
                circle_bg.style.opacity = "0"
                circle_bg.style.pointerEvents = "none"
            })
            circleRef.current.style.opacity = "1"
        }
    }, [menu])

    //移动menu
    const [isDragging, setIsDragging] = useState(false)
    const [canBeCLicked, setCanBeCLicked] = useState(true) //防止在移动后立马点开menu
    const centerMenuSpanRef = useRef(null)
    const [mouseDownPositionX, setMouseDownPositionX] = useState(0)
    const [mouseDownPositionY, setMouseDownPositionY] = useState(0)
    const [touchDownPositionX, setTouchDownPositionX] = useState(0)
    const [touchDownPositionY, setTouchDownPositionY] = useState(0)
    const mouseDown = (e) => {
        e.stopPropagation();
        centerMenu_containerRef.current.style.transition = "none"
        centerMenuButton_containerRef.current.style.transition = "none"
        circleRef.current.style.transition = "none"
        centerMenuButtonRef.current.style.transition = "none"
        centerMenuSpanRef.current.style.transition = "none"

        setIsDragging(true)
        if (e.touches) {
            const initialTouch = e.touches[0];
            setTouchDownPositionX(initialTouch.clientX - centerMenu_containerRef.current.offsetLeft)
            setTouchDownPositionY(initialTouch.clientY - centerMenu_containerRef.current.offsetTop)
            setMouseDownPositionX(0);
            setMouseDownPositionY(0);
        } else {
            setMouseDownPositionX(e.clientX - centerMenu_containerRef.current.offsetLeft);
            setMouseDownPositionY(e.clientY - centerMenu_containerRef.current.offsetTop);
            setTouchDownPositionX(0)
            setTouchDownPositionY(0)
        }
    }

    const mouseMove = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault(); //防止scroll
        if (isDragging === true) {
            setCanBeCLicked(false)
            if (mouseDownPositionX !== 0 && mouseDownPositionY !== 0) {
                let currentPositionX = e.clientX - mouseDownPositionX;
                let currentPositionY = e.clientY - mouseDownPositionY;
                centerMenu_containerRef.current.style.top = currentPositionY + "px";
                centerMenu_containerRef.current.style.left = currentPositionX + "px";
            } else if (touchDownPositionX !== 0 && touchDownPositionY !== 0) {
                const touch = e.touches[0];
                let currentPositionX = touch.clientX - touchDownPositionX;
                let currentPositionY = touch.clientY - touchDownPositionY;
                centerMenu_containerRef.current.style.top = currentPositionY + "px";
                centerMenu_containerRef.current.style.left = currentPositionX + "px";
            }
        }
    }, [isDragging, mouseDownPositionX, mouseDownPositionY, touchDownPositionX, touchDownPositionY])

    const mouseUp = (e) => {
        centerMenu_containerRef.current.style.transition = "0.3s"
        centerMenuButton_containerRef.current.style.transition = "0.3s"
        circleRef.current.style.transition = "0.3s"
        centerMenuButtonRef.current.style.transition = "0.3s"
        centerMenuSpanRef.current.style.transition = "0.3s"
        setIsDragging(false)
        setTimeout(() => { //防止过快mouseUp直接触发click
            setCanBeCLicked(true);
        }, 10)
    }

    // 解决刷新太慢
    useEffect(() => {
        const centerMenuButton = centerMenuButtonRef.current;
        const centerMenuContainer = centerMenu_containerRef.current;

        const handleMouseMove = isDragging ? null : moveLight;

        if (centerMenuButton) {
            centerMenuButton.addEventListener('mousemove', handleMouseMove);
        }

        if (centerMenuContainer) {
            centerMenuContainer.addEventListener('mousemove', mouseMove);
            centerMenuContainer.addEventListener('touchstart', mouseDown);
            centerMenuContainer.addEventListener('touchmove', mouseMove);
            centerMenuContainer.addEventListener('touchend', mouseUp);
            centerMenuContainer.addEventListener('touchcancel', mouseUp);
        }

        return () => {
            if (centerMenuButton) {
                centerMenuButton.removeEventListener('mousemove', handleMouseMove);
            }

            if (centerMenuContainer) {
                centerMenuContainer.removeEventListener('mousemove', mouseMove);
                centerMenuContainer.removeEventListener('touchstart', mouseDown);
                centerMenuContainer.removeEventListener('touchmove', mouseMove);
                centerMenuContainer.removeEventListener('touchend', mouseUp);
                centerMenuContainer.removeEventListener('touchcancel', mouseUp);
            }
        };
    }, [isDragging, mouseMove]);

    //设置旋转
    let menuLi = ["Employment", "Research", "Projects", "Resume", "LinkedIn"]

    //切换页面
    const navigate = useNavigate();
    const [switchPage, setSwitchPage] = useState(false)
    const [footerRef, setFooterRef] = useState(false)
    const titleRef = useRef(null)
    const changePage = (e) => {
        e.stopPropagation();
        let { value } = e.target.dataset
        setSwitchPage(true)

        centerMenu_containerRef.current.style.opacity = "0"
        centerMenu_containerRef.current.style.pointerEvents = "none"
        infosRef.current.style.opacity = "0"
        infosRef.current.style.pointerEvents = "none"
        titleRef.current.style.transform = "translateX(-50%) translateY(-200px)"

        setTimeout(() => {
            setFooterRef(true)

            headerRef.current.style.transform = "translateY(-200px)"
            infosRef.current.style.opacity = "0"
            headerRef.current.style.pointerEvents = "none"
        }, 400);

        setTimeout(() => {
            topFunction()
            if (value === "LinkedIn") {
                window.location.href = `https://www.linkedin.com/in/junjie-wei-195084261/`
            } else if (value === "Resume") {
                window.location.href = `https://cdn.glitch.global/cfac4828-0e45-4a37-ba54-c9a638c201d0/Resume_Junjie%20Wei.pdf?v=1742272237526`
            } else {
                navigate(`/${value}`)
            }
        }, 900);
    }

    return (
        <div id={styles.Main}>
            {/* <h1 ref={titleRef}>{textArray}</h1> */}
            <h1 ref={titleRef}>Victoria's Portfolio</h1>
            <div
                id={styles.header}
                ref={headerRef}
                onMouseOver={showTip}
                onMouseLeave={deleteTip}
                onClick={scrollDown}>
                <div className={styles.title}>
                    <h1 ref={h1Ref}>Hi! This is Victoria!</h1>
                    <h2>Web Dev || SWE</h2>
                </div>
                <div id={styles.meImg}>
                    <img
                        src={"./img/Main/个人照片/me.jpg"}
                        alt={"meImg"}
                        ref={meImgRef}
                    />
                </div>
                <h3 className={styles.tip} ref={tipRef}>Slide down</h3>
                <h3 className={styles.tip2} ref={tip2Ref}>Poke! Poke!</h3>
                {changeHeader ?
                    null
                    :
                    <Bg />
                }
            </div>
            <div className={styles.bgFrame}></div>
            <div className={styles.bgFrame2}></div>
            <div className={styles.bgFrame3}></div>

            <main>
                <div
                    className={styles.centerMenu_container}
                    ref={centerMenu_containerRef}
                    onMouseDown={(e) => mouseDown(e)}
                    onMouseMove={(e) => mouseMove(e)}
                    onMouseLeave={(e) => mouseUp(e)}
                    onMouseUp={(e) => mouseUp(e)}
                    onTouchStart={(e) => mouseDown(e)}
                    onTouchMove={(e) => mouseMove(e)}
                    onTouchEnd={(e) => mouseUp(e)}
                    onTouchCancel={(e) => mouseUp(e)}
                >
                    <div
                        className={styles.centerMenuButton_container}
                        ref={centerMenuButton_containerRef}
                        style={{ "--color": menu ? "rgb(174, 0, 255)" : "gray" }}>
                        <div className={styles.circle} ref={circleRef}></div>
                        <div
                            className={styles.centerMenuButton}
                            ref={centerMenuButtonRef}
                            onClick={canBeCLicked ? openMenu : null}
                            onMouseMove={isDragging ? null : (e) => moveLight(e)} >
                            <span style={{ position: "relative", zIndex: "2" }} ref={centerMenuSpanRef}>Menu</span>
                        </div>
                    </div>
                    <div className={`${styles.circle_bg} color_change2`} ref={(e) => circle_bgRef.current[0] = e}></div>
                    <div className={`${styles.circle_bg} color_change2`} ref={(e) => circle_bgRef.current[1] = e}></div>
                    {menu ?
                        <>
                            {/* <div className={styles.decorate_bg}></div> */}
                            <div className={styles.nav_container}>
                                {menuLi.map((li, index) => (
                                    <div
                                        key={`${index}menuLi`}
                                        onClick={(e) => changePage(e)}
                                        data-value={li}
                                        style={{ "--deg": `${360 / (menuLi.length) * index}deg` }}>
                                        {li === "LinkedIn" ?
                                            (
                                                <div className='color_change'>
                                                    <svg t="1720454470623" className={styles.icon} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1493" >
                                                        <path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m-137.762133-286.378667V397.380267h-102.4V737.621333h102.4z m-51.2-488.448c-33.024 0-54.5792 22.954667-53.9136 53.589334-0.682667 29.218133 20.8896 52.872533 53.248 52.872533 33.672533 0 55.2448-23.6544 55.2448-52.8896-0.682667-30.6176-21.572267-53.572267-54.5792-53.572267z m133.410133 488.448h102.4V541.405867c0-9.728 1.365333-20.1728 4.061867-26.453334 6.724267-19.456 23.569067-39.645867 51.882666-39.645866 37.034667 0 51.882667 29.917867 51.882667 73.762133V737.621333h102.4V535.842133c0-100.181333-50.517333-146.1248-117.9136-146.1248-54.562133 0-88.251733 32.7168-101.7344 54.272h-2.030933l-4.7104-46.609066h-88.9344c1.348267 29.917867 2.696533 66.0992 2.696533 108.544V737.621333z" fill="#0073B1" p-id="1494"></path>
                                                    </svg>
                                                </div>
                                            )
                                            : li === "Resume" ? (
                                                <div className='color_change'>
                                                    <svg t="1720711604961" className={styles.icon} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3400">
                                                        <path d="M797.76995555 743.44448c44.26183111-45.92412445 43.36412445-118.90119111-2.00931555-163.72849778-45.37799111-44.82161778-118.36529778-44.82161778-163.74328889 0-45.37116445 44.82730667-46.26887111 117.80437333-2.00817778 163.72849778-55.80913778 30.53795555-90.61376 88.99128889-90.86520888 152.60899555a29.12597333 29.12597333 0 0 0 8.53333333 20.59605334 29.09070222 29.09070222 0 0 0 20.59036444 8.52878222h291.24494223a29.11573333 29.11573333 0 0 0 29.12483555-29.12483556c-0.24917333-63.61770667-35.05834667-122.07104-90.86748445-152.60899555z m-83.88152888-138.63025778c32.16952889 0 58.24967111 26.07559111 58.24967111 58.24739556 0 32.16839111-26.08014222 58.24853333-58.24967111 58.24853333-32.16725333 0-58.24739555-26.08014222-58.24739556-58.24853333 0-32.17180445 26.08014222-58.24739555 58.24739556-58.24739556z m-113.00295112 262.11669333c13.30289778-51.50833778 59.80501333-87.46325333 113.00295112-87.36995555 53.20135111-0.09329778 99.70346667 35.86161778 113.00522666 87.36995555h-226.00817778z" fill="#13227a" p-id="3401" data-spm-anchor-id="a313x.search_index.0.i1.271a3a81Yn6xsK" ></path>
                                                        <path d="M729.21884445 104.18176H169.59260445c-25.96408889 0-47.19843555 20.12956445-48.98816 45.59189333v694.25607112a49.04504889 49.04504889 0 0 0 48.93468444 45.61009777H493.75004445c1.96380445-60.97123555 29.08728889-117.73383111 73.74848-157.46730667-10.51192889-22.07288889-15.91296-46.43043555-15.60462223-71.5616711 0.52679111-42.89308445 17.62076445-83.11921778 48.13710223-113.26919112 30.52430222-30.14997333 70.95864889-46.75242667 113.85856-46.75242666 22.50410667 0 44.32782222 4.57614222 64.40049777 13.27217778V153.25297778c0-27.14282667-21.98641778-49.07121778-49.07121777-49.07121778zM435.72565333 582.00405333H267.45742222a27.41134222 27.41134222 0 0 1-23.73632-13.70794666 27.40224 27.40224 0 0 1 0-27.41361778 27.40224 27.40224 0 0 1 23.73632-13.70680889h168.26823111c15.14382222 0 27.41361778 12.27093333 27.41361778 27.41361778 0 15.13927111-12.27093333 27.41475555-27.41361778 27.41475555z m102.08824889-167.77329778H267.51658667c-15.14154667 0-27.41475555-12.27207111-27.41475556-27.41361777 0-15.13813333 12.27320889-27.41361778 27.41475556-27.41361778h270.29731555c15.14268445 0 27.41361778 12.27548445 27.41361778 27.41361778 0 15.14154667-12.27093333 27.41361778-27.41361778 27.41361777z m0-145.56501333H267.51658667c-15.14154667 0-27.41475555-12.27548445-27.41475556-27.41816889 0-15.13699555 12.27320889-27.41248 27.41475556-27.41248h270.29731555c15.14268445 0 27.41361778 12.27548445 27.41361778 27.41248 0 15.14268445-12.27093333 27.41816889-27.41361778 27.41816889z" fill="#1296db" p-id="3402" data-spm-anchor-id="a313x.search_index.0.i0.271a3a81Yn6xsK" ></path>
                                                    </svg>
                                                </div>
                                            )
                                                :
                                                (<div className='color_change'>{li}</div>)

                                        }
                                    </div>
                                ))}
                            </div>
                        </>
                        :
                        null
                    }
                </div>
                <div id={styles.infos} ref={infosRef}>
                    <div
                        className={`${styles.basic} ${styles.info}`}
                        ref={(e) => infoRef.current[0] = e}
                        data-value="basicInformation"
                        onMouseOver={(e) => showText(e)}
                        onMouseLeave={deleteText}
                        onClick={(e) => enlarge(e)}>
                        {choose === "basicInformation" ?
                            <>
                                <div className={styles.inner}>
                                    <h3 style={{ textAlign: "center", marginLeft: "0px" }} ref={h3Ref}>Part 1: Basic Information</h3>
                                    <ul ref={ulRef}>
                                        <li ref={(e) => skillLiRef.current[0] = e}><strong>Official name: </strong> Junjie Wei</li>
                                        <li ref={(e) => skillLiRef.current[1] = e}><strong>English name: </strong> Victoria Wei</li>
                                        <li ref={(e) => skillLiRef.current[2] = e}><strong>Gender: </strong> Female</li>
                                        <li ref={(e) => skillLiRef.current[3] = e}><strong>Email: </strong> w290610058@gmail.com</li>
                                        <li ref={(e) => skillLiRef.current[4] = e}>
                                            <strong>Bio: </strong> Ever since I was a child, I have shown
                                            tremendous interest in computer and design. I think I'm a creative person.
                                            I have been exposed to computers since I was very young and completed numerous projects,
                                            including game maps, websites, and app designs.
                                            These experiences have fueled my passion for learning more about design and programming.
                                        </li>
                                        <li ref={(e) => skillLiRef.current[5] = e}><strong>Hobby: </strong> Computer games, Lego, Designing and Coding websites, Designing games</li>
                                    </ul>
                                </div>
                                <div className={styles.outer}></div>
                            </>
                            :
                            <div style={{ pointerEvents: "none" }}>
                                <div style={{ pointerEvents: "none" }}><strong className={styles.outerTitle}>Part 1: Basic Information</strong></div>
                                <div style={{ pointerEvents: "none" }} className={styles.showText} ref={(e) => showTextRef.current[0] = e}></div>
                                <div className={styles.outer}></div>
                                {large === false ?
                                    <>
                                        <div className={styles.decorate}></div>
                                        {/* <div className={styles.decorate2}></div> */}
                                    </>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                    <div
                        className={`${styles.pictures} ${styles.info}`}
                        ref={(e) => infoRef.current[1] = e}
                        data-value="pictures"
                        onMouseOver={(e) => showText(e)}
                        onMouseLeave={deleteText}
                        onClick={(e) => enlarge(e)}>
                        {choose === "pictures" ?
                            <>
                                <div className={styles.inner}>
                                    <div className={styles.imgGallery_container}>
                                        <ImgGallery imgs={imgs} />
                                    </div>
                                </div>
                                <div className={styles.outer}></div>
                            </>
                            :
                            <div style={{ pointerEvents: "none" }}>
                                <div style={{ pointerEvents: "none" }}><strong className={styles.outerTitle}>Part 2: My pictures</strong></div>
                                <div style={{ pointerEvents: "none" }} className={styles.showText} ref={(e) => showTextRef.current[1] = e}></div>
                                <div className={styles.outer}></div>
                                {large === false ?
                                    <>
                                        <div className={styles.decorate}></div>
                                        {/* <div className={styles.decorate2}></div> */}
                                    </>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                    <div
                        className={`${styles.education} ${styles.info}`}
                        ref={(e) => infoRef.current[2] = e}
                        data-value="education"
                        onMouseOver={(e) => showText(e)}
                        onMouseLeave={deleteText}
                        onClick={(e) => enlarge(e)}>
                        {choose === "education" ?
                            <>
                                <div className={styles.inner}>
                                    <h3 style={{ textAlign: "center", marginLeft: "0px" }} ref={h3Ref}>Part 3: Education</h3>
                                    <ul ref={ulRef}>
                                        <div ref={(e) => skillLiRef.current[0] = e}>
                                            <li ><strong>1. Bachelor</strong></li>
                                            <li>
                                                <strong>University: </strong> University of Washington
                                            </li>
                                            <li ><strong>Year: </strong> 2020.9-2023.12</li>
                                            <li ><strong>Degree: </strong> Informatics - HCI</li>
                                            <li ><strong>GPA: </strong> 3.92/4.0</li>
                                        </div>
                                        <br />
                                        <div ref={(e) => skillLiRef.current[1] = e}>
                                            <li style={{ display: "flex", justifyContent: "end" }}><strong>2. Master</strong></li>
                                            <li style={{ display: "flex", justifyContent: "end" }}>
                                                Carnegie Mellon University <strong>&nbsp;:University</strong>
                                            </li>
                                            <li style={{ display: "flex", justifyContent: "end" }}>2024.8-2025.12<strong>&nbsp;:Year</strong> </li>
                                            <li style={{ display: "flex", justifyContent: "end" }}>Computer Software Engineering<strong>&nbsp;:Degree</strong> </li>
                                        </div>
                                        <br />
                                        <div ref={(e) => skillLiRef.current[2] = e}>
                                            <li ><strong>-- Regular</strong></li>
                                            <li ><strong>GRE: </strong> 332/340</li>
                                            <li ><strong>TOEFL: </strong> 109/120</li>
                                        </div>
                                    </ul>
                                </div>
                                <div className={styles.outer}></div>
                            </>
                            :
                            <div style={{ pointerEvents: "none" }}>
                                <div style={{ pointerEvents: "none" }}><strong className={styles.outerTitle}>Part 3: Education</strong></div>
                                <div style={{ pointerEvents: "none" }} className={styles.showText} ref={(e) => showTextRef.current[2] = e}></div>
                                <div className={styles.outer}></div>
                                {large === false ?
                                    <>
                                        {/* <div className={styles.decorate}></div> */}
                                        <div className={styles.decorate2}></div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                    <div
                        className={`${styles.skill} ${styles.info}`}
                        ref={(e) => infoRef.current[3] = e}
                        data-value="skill"
                        onMouseOver={(e) => showText(e)}
                        onMouseLeave={deleteText}
                        onClick={(e) => enlarge(e)}>
                        {choose === "skill" ?
                            <>
                                <div className={styles.inner}>
                                    <h3 style={{ textAlign: "center", marginLeft: "0px" }} ref={h3Ref}>Part 4: Skills</h3>
                                    <ul ref={ulRef}>
                                        <li>
                                            <strong>Programming Languages:</strong> JavaScript, HTML, CSS, Java, Python, R, SQL
                                        </li>
                                        <li>
                                            <strong>Frameworks:</strong> React.js, Node.js, Express, Spring Boot, MyBatis, Jest
                                        </li>
                                        <li>
                                            <strong>Databases:</strong> MySQL (Workbench), NoSQL (MongoDB, Firebase)
                                        </li>
                                        <li>
                                            <strong>Cloud:</strong> AWS, Heroku, Render, Stripe API, Google Map API
                                        </li>
                                        <li>
                                            <strong>AI Tool:</strong> ChatGPT API, DeepSeek, Figma, Midjourney
                                        </li>
                                        <li>
                                            <strong>Project Control:</strong> Agile, Scrum, Kanban, Git
                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.outer}></div>
                            </>
                            :
                            <div style={{ pointerEvents: "none" }}>
                                <div style={{ pointerEvents: "none" }}><strong className={styles.outerTitle}>Part 4: Skills</strong></div>
                                <div style={{ pointerEvents: "none" }} className={styles.showText} ref={(e) => showTextRef.current[3] = e}></div>
                                <div className={styles.outer}></div>
                                {large === false ?
                                    <>
                                        {/* <div className={styles.decorate}></div> */}
                                        <div className={styles.decorate2}></div>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    choose === "skill" ?
                        < div className={styles.scrolls_container}>
                            <div className={styles.scroll_container} style={{ "--t": "25s" }}>
                                <div>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                </div>
                                <div>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                    <span>JavaScript</span>
                                    <span>HTML</span>
                                    <span>CSS</span>
                                    <span>Java</span>
                                    <span>Python</span>
                                    <span>R</span>
                                    <span>SQL</span>
                                </div>
                            </div>
                            <div className={styles.scroll_container} style={{ "--t": "20s" }}>
                                <div>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                </div>
                                <div>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                    <span>React.js</span>
                                    <span>Node.js</span>
                                    <span>Express.js</span>
                                    <span>Spring Boot</span>
                                    <span>MyBatis</span>
                                    <span>Jest</span>
                                </div>
                            </div>
                            <div className={styles.scroll_container} style={{ "--t": "25s" }}>
                                <div>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>

                                </div>
                                <div>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                    <span>MySQL (Navicat, MySQL Workbench)</span>
                                    <span>NoSQL (MongoDB, Firebase)</span>
                                </div>
                            </div>

                            <div className={styles.scroll_container} style={{ "--t": "20s" }}>
                                <div>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                </div>
                                <div>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                    <span>AWS</span>
                                    <span>Heroku</span>
                                    <span>Render</span>
                                    <span>Stripe API</span>
                                    <span>Google Map API</span>
                                </div>
                            </div>

                            <div className={styles.scroll_container} style={{ "--t": "25s" }}>
                                <div>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                </div>
                                <div>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                    <span>ChatGPT API</span>
                                    <span>DeepSeek</span>
                                    <span>Figma</span>
                                    <span>Midjourney</span>
                                </div>
                            </div>

                            <div className={styles.scroll_container} style={{ "--t": "20s" }}>
                                <div>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                </div>
                                <div>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                    <span>Agile</span>
                                    <span>Scrum</span>
                                    <span>Kanban</span>
                                    <span>Git</span>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </main >
            <Bg />
            <Footer footerRef={footerRef} />
        </div >
    );
}